﻿///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
(function( doc ) {
    'use strict';
        
    class HomeManager {
        constructor() {
            this.hdr = app.$( '#hdr-main' );
            this.addEventListeners();
        }

        addEventListeners() {
            if ( document.body.classList.contains( 'home' ) ) { 
                window.addEventListener( 'scroll', e => this.toggleHeaderBg() );
            }
        }

        toggleHeaderBg() {
            if ( app.util.getWindowScrollPosition() >= 25 ) {
                document.body.classList.add( 'hdr-white' );
            }
            else {
                document.body.classList.remove( 'hdr-white' );
            }
        }
    }

    let hm = new HomeManager(); 

}( document ) );